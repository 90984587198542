import React from 'react'
import { Col, Row, Table } from 'antd'
import styles from './TopConversions.module.sass'
import Link from 'next/link'

const renderCell = (value) => {
  // if (value.from === 'mov' && value.to === 'mp4') {
  //   return <a href="https://btclod.com" rel="dofollow" target="_blank">{value.text}</a>
  // } else if (value.from === 'midi' && value.to === 'mp3') {
  //   return <a href="https://mp3download.to" rel="dofollow" target="_blank">{value.text}</a>
  // }
  return (
    <Link href='/[type]/[format]' as={`/${value.type}/${value.from}-to-${value.to}-online-converter`}>
      <a>
        {value.text}
      </a>
    </Link>
  )
}

const columns = [
  {
    title: 'C1',
    dataIndex: 'c1',
    render: (value) => renderCell(value)
  },
  {
    title: 'C2',
    dataIndex: 'c2',
    render: (value) => renderCell(value)
  },
  {
    title: 'C3',
    dataIndex: 'c3',
    render: (value) => renderCell(value)
  },
  {
    title: 'C4',
    dataIndex: 'c4',
    render: (value) => renderCell(value)
  },
  {
    title: 'C5',
    dataIndex: 'c5',
    render: (value) => renderCell(value)
  },

  {
    title: 'C6',
    dataIndex: 'c6',
    render: (value) => renderCell(value)
  },
];

const data = [
  {
    key: '1',
    c1: { text: 'MP4 To MP3 converter', type: 'video', from: 'mp4', to: 'mp3' },
    c2: { text: 'M4A To MP3 converter', type: 'video', from: 'm4a', to: 'mp3' },
    c3: { text: 'MOV to MP4 converter', type: 'video', from: 'mov', to: 'mp4' },
    c4: { text: 'WAV To MP3 converter', type: 'audio', from: 'wav', to: 'mp3' },
    c5: { text: 'MP3 To WAV converter', type: 'audio', from: 'mp3', to: 'wav' },
    c6: { text: 'MKV To MP4 converter', type: 'video', from: 'mkv', to: 'mp4' }
  },
  {
    key: '2',
    c1: { text: 'WEBM To MP4 converter', type: 'video', from: 'webm', to: 'mp4' },
    c2: { text: 'OGG To MP3 converter', type: 'audio', from: 'ogg', to: 'mp3' },
    c3: { text: 'MP3 To OGG converter', type: 'audio', from: 'mp3', to: 'ogg' },
    c4: { text: 'FLV To MP3 converter', type: 'video', from: 'flv', to: 'mp3' },
    c5: { text: 'MP3 To WAV converter', type: 'audio', from: 'mp3', to: 'wav' },
    c6: { text: 'MKV To MP4 converter', type: 'video', from: 'mkv', to: 'mp4' }
  },
  {
    key: '3',
    c1: { text: 'FLV To MP4 Converter', type: 'video', from: 'flv', to: 'mp4' },
    c2: { text: 'MP3 To MIDI Converter', type: 'audio', from: 'mp3', to: 'midi' },
    c3: { text: 'MP4 To WAV Converter', type: 'video', from: 'mp4', to: 'wav' },
    c4: { text: 'WMV To MP4 Converter', type: 'video', from: 'wmv', to: 'mp4' },
    c5: { text: 'MOV To MP3 Converter', type: 'video', from: 'mov', to: 'mp3' },
    c6: { text: 'MP4 To MOV Converter', type: 'video', from: 'mp4', to: 'mov' }
  },
  {
    key: '4',
    c1: { text: 'MP4 To WEBM Converter', type: 'video', from: 'mp4', to: 'webm'},
    c2: { text: 'FLAC To MP3 Converter', type: 'audio', from: 'flac', to: 'mp3' },
    c3: { text: 'WMA To MP3 Converter', type: 'audio', from: 'wma', to: 'mp3' },
    c4: { text: 'MIDI To MP3 Converter', type: 'audio', from: 'midi', to: 'mp3' },
    c5: { text: 'MP4 To AVI Converter', type: 'video', from: 'mp4', to: 'avi' },
    c6: { text: 'MP3 To M4R Converter', type: 'audio', from: 'mp3', to: 'm4r'}
  },
];

const TopConversionsTable = () => (
  <section className={styles.feature}>
    <div className='container'>
      <Row>
        <Col xs={24} lg={20} className='mx-auto'>
          <h3 className={`${styles.title} text-center mx-auto`}>Top conversions</h3>
          <Table
            dataSource={data}
            columns={columns}
            showHeader={false}
            bordered={true}
            pagination={false}
            className={'custom_table_top_conversion'}
          />
        </Col>
      </Row>
    </div>
  </section>
)

export default TopConversionsTable
