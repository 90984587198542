import { Col, Row } from 'antd'
import styles from './style.module.sass'
import FeatureItem from './index'
import React from 'react'

export default function FeatureListContainer({t}) {
  return <div className='container'>
    <Row>
      <Col xs={24} lg={20} className='mx-auto'>
        <h2 className={`section_title mx-auto`}>
          {t('common:convert_edit_optimize')}
        </h2>
        <p className={`sub_tile text-center mx-auto`}>
          {t('common:sub_convert_edit_optimize')}
        </p>
        <div className={styles.featuresContainer}>
          {
            t('common:features', { returnObjects: true }).map((item) => (
              <FeatureItem logoClassName={styles.featureLogo} as={item.as} link={item.href || '/'} isActive={item.is_active} logo={item.icon} title={item.tile} desc={item.sub_tile} key={item.key}/>
            ))
          }
        </div>
      </Col>
    </Row>
  </div>
}
