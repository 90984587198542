import { useTranslation } from 'next-i18next'
import React from 'react'
import styles from './style.module.sass'

export default function GuidingBlock () {
  const { t } = useTranslation()
  return <section className={`container content`}>
    <div className={`${styles.guideContainer}`}>
      <h2
        className={'section_title'}>{t('common:guiding.title')}</h2>

      <div className={styles.blockContainer}>
        {[1,2,3].map((item) => <div className={styles.blockItem} key={item.toString()}>
          <img alt={'number'} src={require(`public/images/number-${item}.png`)}/>
          <div className={'content mx-3'}>
            <span className={'title weight-500'}>
              {t(`common:guiding.stepTitle.${item}`)}
            </span>
            <span className={'mt-3 color-secondary-dark'}>
              {t(`common:guiding.stepDesc.${item}`)}
            </span>
          </div>
        </div>)}
      </div>
    </div>
  </section>
}
