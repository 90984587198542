import React from 'react'

import Footer from '../components/Footer'
import FooterArchievement from '../components/Footer/FooterArchievement'
import TopConversionsTable from '../components/TopConversions'

import { useSelector } from 'react-redux'

import { useTranslation } from 'next-i18next'
import { NextSeo } from 'next-seo'

import styles from './home.module.sass'
import getLanguage from '../utils/i18n'
import HeaderBar from '../components/HeaderBar'
import GuidingBlock from '../components/GuidingBlock'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import DragDropContainer from '../components/DragDrop/DragDropContainer'
import FeatureListContainer from '../components/FeatureItem/FeatureListContainer'

const Home = () => {
  const { t } = useTranslation()

  const { statistic } = useSelector(state => state.fileList)

  // useEffect(() => {
  //   // (window.adsbygoogle = window.adsbygoogle || []).push({})
  //   try {
  //     dispatch(fetchStatistics())
  //   } catch (error) {
  //     console.log(error);
  //   }
  //   window.addEventListener('scroll', handleScroll)
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll)
  //   }
  // }, [])

  // const handleScroll = () => {
  //   const adsContainer = document.querySelector('div.ads_container')
  //   if (adsContainer) {
  //     const distanceScroll = document.body.scrollTop
  //     const elRect = adsContainer.getBoundingClientRect()
  //     const elOffset = elRect.top
  //     setAdsOffset(distanceScroll + elOffset)
  //   }
  // }

  return (
    <div>
      <NextSeo
        title={t('common:slogan')}
        canonical = 'https://evano.com'
      />
      <HeaderBar />
      <DragDropContainer dragDropTitle={t('common:file_converter')} subtitle={t('common:sub_slogan')} title={t('common:slogan')} dragDropSubtitle={t('common:convert_your_file')}/>

      <GuidingBlock/>

      <section className={styles.feature}>
        <div className='ads_container mx-auto' style={{position: 'relative'}}>
          {/*<div key='v__adsbygoogle_left' className={`v__adsbygoogle left ${adsOffset < 676 ? 'fixed' : ''}`}>*/}
          {/*  { isXl || isXxl ? (<GoogleAdsense*/}
          {/*    className="adsbygoogle"*/}
          {/*    style={{display: 'inline-block', 'min-width': '200px', 'min-height': '50px'}}*/}
          {/*    client='ca-pub-6022273213615485'*/}
          {/*    slot='6680291945'*/}
          {/*    format='auto'*/}
          {/*    responsive={true} />) : null }*/}
          {/*</div>*/}

          <FeatureListContainer t={t}/>
          {/*<div key='v__adsbygoogle_right' className={`v__adsbygoogle right ${adsOffset < 676 ? 'fixed' : ''}`}>*/}
          {/*  { isXl || isXxl ? (<GoogleAdsense*/}
          {/*    className="adsbygoogle"*/}
          {/*    style={{display: 'inline-block', 'min-width': '200px', 'min-height': '50px'}}*/}
          {/*    client='ca-pub-6022273213615485'*/}
          {/*    slot='6680291945'*/}
          {/*    format='auto'*/}
          {/*    responsive={true} />) : null }*/}
          {/*</div>*/}
        </div>
      </section>
      <TopConversionsTable />
      <section className='pre_footer'>
      { statistic ? <FooterArchievement totalFile={statistic.total_file} totalFileSize={statistic.total_file_size}/> : null }
      </section>
      <Footer />
    </div>
  )
}

export const getStaticProps = async (ctx) => ({
  props: await serverSideTranslations(getLanguage(ctx), ['common', 'header', 'footer'])
})

export default Home
